import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { Vimeo } from "@swizec/gatsby-theme-course-platform";
export const title = "Module 3";
export const description = "";
export const image = "/chapter_headers/serverless-elements.png";
export const _frontmatter = {};
const layoutProps = {
  title,
  description,
  image,
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <h1 {...{
      "id": "building-static-pages-dynamically"
    }}>{`Building static pages dynamically`}</h1>
    <p>{`Here we use GraphQL at compile time to create all those user landing pages as actual Gatsby pages in our app.`}</p>
    <h2 {...{
      "id": "add-an-allpages-query-on-the-server"
    }}>{`Add an allPages query on the server`}</h2>
    <Vimeo id="393475422" mdxType="Vimeo" />
    <h2 {...{
      "id": "dynamically-create-static-gatsby-pages"
    }}>{`Dynamically create static Gatsby pages`}</h2>
    <Vimeo id="393476022" mdxType="Vimeo" />
    <h2 {...{
      "id": "render-created-pages"
    }}>{`Render created pages`}</h2>
    <Vimeo id="393695621" mdxType="Vimeo" />
    <h2 {...{
      "id": "fetch-fresh-data-on-page-load"
    }}>{`Fetch fresh data on page load`}</h2>
    <Vimeo id="394232762" mdxType="Vimeo" />
    <h2 {...{
      "id": "add-page-query-to-server"
    }}>{`Add page query to server`}</h2>
    <Vimeo id="394233203" mdxType="Vimeo" />
    <h2 {...{
      "id": "show-content-and-editor-side-by-side"
    }}>{`Show content and editor side-by-side`}</h2>
    <Vimeo id="394233582" mdxType="Vimeo" />
    <h2 {...{
      "id": "render-markdown-with-remark"
    }}>{`Render markdown with Remark`}</h2>
    <Vimeo id="394702938" mdxType="Vimeo" />
    <p>{`We're using `}<a parentName="p" {...{
        "href": "https://github.com/remarkjs/remark"
      }}>{`remark`}</a>{` to render our editable Markdown content. It's the same rendering engine that Gatsby uses when you want to use markdown pages with gatsby-plugin-remark. That approach is meant for static content uneditable by users hoewver.`}</p>
    <p>{`You can read about the details behind this code in `}<a parentName="p" {...{
        "href": "https://swizec.com/blog/buildremark-plugin-supercharge-static-site/swizec/8860"
      }}>{`my article about building remark plugins`}</a>{` and my `}<a parentName="p" {...{
        "href": "https://swizec.com/blog/custom-markdown-extensions-with-remark-and-hast-handlers/swizec/9018"
      }}>{`article on custom markdown extensions`}</a></p>
    <p>{`I used those to build `}<a parentName="p" {...{
        "href": "https://techletter.app"
      }}>{`TechLetter.App`}</a>{`. We use a simplified `}<inlineCode parentName="p">{`useRemark`}</inlineCode>{` hook for our project.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`import { useState, useEffect } from "react"
import remark from "remark"
import remark2react from "remark-react"

export const remarkCompile = input =>
  new Promise((resolve, reject) => {
    remark()
      .use(remark2react, {
        sanitize: false,
      })
      .process(input, (err, output) => {
        if (err) {
          reject(err)
        } else {
          resolve(output)
        }
      })
  })

export default function useRemark(input) {
  const [rendered, setRendered] = useState("")

  useEffect(() => {
    remarkCompile(input)
      .then(output => setRendered(output.contents))
      .catch(err => console.error(err))
  }, [input])

  return rendered
}
`}</code></pre>
    <h2 {...{
      "id": "live-edit-page"
    }}>{`Live edit page`}</h2>
    <Vimeo id="394703083" mdxType="Vimeo" />
    <h2 {...{
      "id": "editing-requires-auth"
    }}>{`Editing requires auth`}</h2>
    <Vimeo id="394703224" mdxType="Vimeo" />
    <h2 {...{
      "id": "add-save-button"
    }}>{`Add save button`}</h2>
    <Vimeo id="394976748" mdxType="Vimeo" />
    <h2 {...{
      "id": "savepage-mutation"
    }}>{`savePage mutation`}</h2>
    <Vimeo id="394977281" mdxType="Vimeo" />
    <h2 {...{
      "id": "debug-odd-savepage-issue"
    }}>{`debug odd savePage issue`}</h2>
    <p>{`This is a little painful to watch and the mistake was very dumb in the end - not mapping the `}<inlineCode parentName="p">{`savePage`}</inlineCode>{` resolver to the `}<inlineCode parentName="p">{`savePage`}</inlineCode>{` mutation. 🤦‍♂️`}</p>
    <p>{`Video included because it's a good example of step by step debugging that you'll need when debugging your own project :)`}</p>
    <Vimeo id="394977952" mdxType="Vimeo" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      